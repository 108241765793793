<template>
  <div class="table-responsive">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="ps-1" colspan="4">
            <p class="mb-0">
              Rol van de gebruiker
            </p>
          </th>
          <th class="text-center">
            <p class="mb-0">Actief</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="ps-1" colspan="12">
            <div class="my-n1">
              <div
                v-for="{ id, name } in roles"
                :key="id"
                class="d-flex align-items-center py-1"
              >
                <input
                  :id="`permission-role-${parseIdFromString(name)}`"
                  v-model="roleIdHandler"
                  name="permission-role"
                  type="radio"
                  :value="id"
                />
                
                <label
                  class="flex-grow-1 ps-1 m-0"
                  :for="`permission-role-${parseIdFromString(name)}`"
                >
                  {{ name }}
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="ps-1" colspan="4">
            <div class="my-auto">
              <span class="text-dark d-block text-sm">Deze gebruiker is een bestuurslid</span>
              <span class="text-xs font-weight-normal"
                >Er wordt een extra icon getoond in de app</span
              >
            </div>
          </td>
          <td>
            <div class="ms-auto">
              <material-switch
                id="permission-boardmember"
                v-model="isBoardMemberHandler"
                class="form-check form-switch mb-0 align-items-center justify-content-center"
                name="permission-boardmember"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    v-show="isBoardMember"
    class="row mt-4"
  >
    <material-input
      id="boardmember-function"
      v-model="boardMemberFunctionHandler"
      variant="static"
      label="Bestuurs functie"
      placeholder="Vul de bestuursfunctie in"
    />
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { getAll } from '@/api/providers/roles';
import { parseIdFromString } from '@/helpers/parsers';
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';

export default {
  name: 'UserPermissions',

  components: {
    MaterialButton,
    MaterialInput,
    MaterialSwitch,
  },

  props: {
    boardMemberFunction: {
      type: String,
      required: true,
    },
    isBoardMember: {
      type: Boolean,
      required: true,
    },
    roleId: {
      type: Number,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:boardMemberFunction',
    'update:isBoardMember',
    'update:roleId',
  ],

  data: () => ({
    roles: [],
  }),

  computed: {
    ...createVModelHandlers(
      'boardMemberFunction',
      'isBoardMember',
      'roleId',
    ),
  },

  async mounted() {
    await this.loadRoles();
    
    if (!this.roleIdHandler) {
      this.setDefaultRoleId();
    }
  },

  methods: {
    loadRoles() {
      return getAll()
        .then((roles) => this.roles = roles);
    },

    parseIdFromString,

    setDefaultRoleId() {
      const defaultRole = this.roles
        .find(({ isDefault }) => isDefault);

      if (defaultRole === undefined) {
        return;
      }

      this.roleIdHandler = defaultRole.id;
    },
  },
};
</script>
