<template>
  <router-link
    class="project-card col-sm-4 mb-4 d-inline-block px-1"  
    :to="`/group-overview/${ projectId }`"
  >

    <div
        class="card card-background align-items-start"
        :class="{
          'card-background-mask-dark': projectId > 0,
          'card-background-mask-primary': projectId < 1
        }"
    >
      <div
        class="d-block w-100"
      >
        <div 
          class="full-background cursor-pointer"
        >
        </div>
        <div 
          class="card-body"
        >
          <div 
            v-if="!projectId"
            class="float-end"
          >
            <i 
              class="material-icons text-white"
              style="font-size:60px;"
            >
              add_circle_outline
            </i>
          </div>

          <h5
            class="text-white mb-0"
          >
            {{ title }}
          </h5>
          <dropdown-menu
              :items="[
                {
                  handler: removeUser,
                  name: 'Gebruiker loskoppelen',
                },
              ]"
            />
          </div>
        </div>
      </div>
  </router-link>
</template>

<script>
import DropdownMenu from '@/components/UI/DropdownMenu.vue';
import { createYesNoSweetAlert } from '@/helpers/sweetalert';

export default {
  components: {
    DropdownMenu
  },

  props: {
    projectId: { type: Number, required: true },
    title: { type: String, required: true },
    subTitle: { type: String, required: true },
    image: { type: String, default: "" }
  },

  emits: [ 'deleted' ],

  methods: {
    removeUser(e) {
      e.preventDefault();
      
      createYesNoSweetAlert(
        `Wilt u <b>${this.title}</b> loskoppelen van deze gebruiker?`,
        () => this.$emit('deleted', this.projectId)
      );
    }
  }
}
</script>

<style scoped lang="scss">
.card-body {
  position: relative;

  .dropdown {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    .btn {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}

.news-card {
  &:nth-child(n + 5) {
    margin-top: var(--bs-gutter-x);
  }

  &__image {
    object-fit: cover;

    &-container {
      height: 200px;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__reactions p {
    margin-bottom: 0;
  }
}
</style>