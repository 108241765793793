<template>
  <div class="row mt-4">
    <div class="col-4">
      <material-input
        id="first-name"
        v-model="firstNameHandler"
        is-required
        variant="static"
        label="Voornaam"
        placeholder="Vul voornaam in"
      />
    </div>
    <div class="col-4">
      <material-input
        id="insertion"
        v-model="insertionHandler"
        variant="static"
        label="Tussenvoegsel"
        placeholder="Vul tussenvoegsel in"
      />
    </div>
    <div class="col-4">
      <material-input
        id="last-name"
        v-model="lastNameHandler"
        variant="static"
        label="Achternaam"
        placeholder="Vul achternaam in"
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6">
      <material-input
        id="email"
        v-model="emailAddressHandler"
        is-required
        type="email"
        variant="static"
        label="E-mailadres"
        placeholder="Vul e-mailadres in"
      />
    </div>
    <div class="col-6">
      <material-input
        id="phone-number"
        v-model="phoneNumberHandler"
        type="phone"
        variant="static"
        label="Telefoonnummer"
        placeholder="Vul telefoonnummer in"
      />
    </div>
  </div>
  
  <div class="row mt-4">
    <material-textarea
      id="company-description"
      v-model="descriptionHandler"
      variant="static"
      label="Omschrijving"
      placeholder="Vul hier een beschrijving in"
      :rows="3"
    />
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';

export default {
  name: 'BasicInfo',

  components: {
    MaterialButton,
    MaterialInput,
    MaterialTextarea,
  },

  props: {
    description: {
      type: String,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    insertion: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:description',
    'update:emailAddress',
    'update:firstName',
    'update:insertion',
    'update:lastName',
    'update:phoneNumber',
  ],

  computed: {
    ...createVModelHandlers(
      'description',
      'emailAddress',
      'firstName',
      'insertion',
      'lastName',
      'phoneNumber',
    ),
  },
};
</script>
