<template>
  <div class="row mt-4">
    <material-input
      id="linkedin"
      v-model="linkedInHandler"
      variant="static"
      label="Linkedin"
      placeholder="Vul hier de volledige linkedin-url in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="twitter"
      v-model="twitterHandler"
      variant="static"
      label="Twitter"
      placeholder="Vul hier je twitter handle in (Incl @...)"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="instagram"
      v-model="instagramHandler"
      variant="static"
      label="Instagram"
      placeholder="Vul hier je Instagram-url in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="facebook"
      v-model="facebookHandler"
      variant="static"
      label="Facebook"
      placeholder="Vul hier de volledige facebook url in"
    />
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  name: 'UserSocials',

  components: {
    MaterialButton,
    MaterialInput,
  },

  props: {
    facebook: {
      type: String,
      required: true,
    },
    linkedIn: {
      type: String,
      required: true,
    },
    twitter: {
      type: String,
      required: true,
    },
    instagram: {
      type: String,
      required: true
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:facebook',
    'update:linkedIn',
    'update:twitter',
    'update:instagram',
  ],

  computed: {
    ...createVModelHandlers(
      'facebook',
      'linkedIn',
      'instagram'
    ),

    twitterHandler: {
      get() {
        return '@' + this.twitter;
      },
      set(value) {
        this.$emit('update:twitter', value.replaceAll('@', ''));
      },
    },
  },
};
</script>
