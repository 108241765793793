import { getAllActive as getActiveModules } from "../api/providers/modules";
  
export async function moduleIsActive(moduleName) {
  try {
    const modules = await getActiveModules();
    const module = modules.find(m => m.name === moduleName);

    return module !== undefined &&
      module.isDefaultActive || module.isActive;
  }
  catch {
    return false;
  }
}