<template>
  <div class="col-4">
    <dropdown
      label="Club toevoegen"
      placeholder-label="club"
      :options="apiClubs"
      @update:value="handleClubDropDownEvent"
    />
</div>
  <div
    v-for="club in filteredClubs"
    v-bind:key="club.id"
    class="row mt-2 d-flex align-items-center flex-row"
  >
    <div 
      class="col d-flex align-items-center"
    >
      <div
        class="ratio ratio-1x1 border-radius-lg"
        :style="{
          width: '50px',
          height: '50px',
          backgroundSize: 'cover',
          backgroundImage: getBackgroundImageUrl(club.profilePicture)
        }"
      />
      <h6 class="ms-4"> 
        <router-link
          :to="`/club/${club.id}`"
        >
          {{ club.clubName }}
        </router-link>
        <span class="user-functions-list">
          {{ createFunctionsString(club) }}
        </span>
      </h6>
    </div>

    <div class="col d-flex align-items-center justify-content-end">
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0 me-2"
        title="Functies aanpassen"
        :style="{ width: '50px' }"
        @click.prevent="editUserFunctions(club)"
        @submit.prevent=""
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          tune
        </i>
      </button>
       
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0"
        :style="{ width: '50px' }"
        @click.prevent="removeClub(club)"
        @submit.prevent=""
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          delete_outline
        </i>
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div 
      class="col-12"
    >
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submitUpdatedUsers"
      >
        Update gegevens
      </material-button>
    </div>
  </div>

  <user-functions-modal
    :user-name="modal.userName"
    :functions-for-user="modal.functionsForUser"
    :club-id="modal.clubId"
    :user-id="modal.userId"
    :custom-function-name="modal.customUserFunctionString"
    @update:user-state="updateUserFunctionDictionary"
  />

</template>

<script>
import { Modal } from 'bootstrap';
import { getUserFunctions, removeUser as removeClubUser } from "@/api/providers/club";
import { getAll } from "@/api/providers/club";

import Dropdown from '../../UI/Dropdown.vue';

import MaterialButton from '@/components/MaterialButton.vue';
import UserFunctionsModal from '@/components/Club/Edit/UserFunctionsModal.vue';
import { createYesNoSweetAlert } from '@/helpers/sweetalert.js'

import { CUSTOM_USER_FUNCTION_ID } from '../../../helpers/user-functions';

export default {
  name: 'UserClubs',

  components: {
    Dropdown,
    MaterialButton,
    UserFunctionsModal
  },

  props: {
      userId: { type: Number, required: true },
      fullName: { type: String, required: true },
      clubs: { type: Array, required: true },
      showUpdate: { type: Boolean, default: true }
  },

  emits: [
    'update:clubs',
    'update:user-functions-state'
  ],

  mounted() {
    getAll()
      .then(fullClubList => {
        this.apiClubs = fullClubList.map(c => {
          return {
            value: c.id, 
            label: c.clubName,
            image: c.profilePicture
          };
        })
      });

      this.modal.element = new Modal('#user-function-modal', {});
  },

  data() {
    return {
      currentUserClubs: undefined,
      apiClubs: [],

      modal: {
        userName: '',
        userId: 0,
        clubId: 0,
        element: undefined,
        functionsForUser: [],
        customUserFunctionString: ''
      },

      updatedUserFunctions: []
    };
  },

  computed: {
    filteredClubs() {
      return this.clubsHandler.filter(c => !c.isDeleted);
    },

    clubsHandler: {
      get() { 
        return this.currentUserClubs !== undefined
        ? this.currentUserClubs
        : this.clubs;
      },
      set(value)
      {
        this.currentUserClubs = value;
        this.$emit('update:clubs', this.clubsHandler);
      }
    }
  },

  methods: {
    getBackgroundImageUrl(url)
    {
      return url != null
        ? `url(${url})`
        : 'url(/assets/img/company/user_placeholder.png)';
    },

    addClub(clubId) {
      const club = this.apiClubs.find(u => u.value === clubId);

      if (club !== undefined)
      {
        this.clubsHandler = this.clubsHandler.concat([{
          id: club.value,
          clubName: club.label,
          profilePicture: club.image,
          isNew: true
        }]);
      }
    },
    
    removeClub(club) {
      createYesNoSweetAlert(
        `Weet u zeker dat u ${club.clubName} wilt verwijderen?`,
        () => {
          if (this.$route.params?.id !== undefined) {
          removeClubUser(club.id, this.$route.params?.id);
          this.clubsHandler = this.clubsHandler.filter(c => c != club);
          return;
        }
        
        this.clubsHandler = this.clubsHandler.filter(c => c != club);
        this.updatedUserFunctions = this.updatedUserFunctions.filter(e => e.clubId != club.id);
        this.$emit('update:user-functions-state', this.updatedUserFunctions); 
      });
    },

    markClubAsDeleted(club) {
      let clubs = this.clubsHandler;
      let index = clubs.findIndex(c => c === club);
      if (index !== -1) {
        clubs[index] = { ...club, isDeleted: true };

        this.clubsHandler = clubs;
      }
    },

    async editUserFunctions(club) {
      this.modal.userName = club.clubName;
      this.modal.clubId = club.id;
      this.modal.userId = this.$route.params?.id;

      try {
        let functions = await getUserFunctions(club.id, this.$route.params?.id);
        
        this.modal.functionsForUser = functions.concat(
          this.updatedUserFunctions.filter(f => 
            f.clubId == club.id &&
            f.userId == (this.$route.params?.id ?? 0)
        ));

        const customFunction = functions.find(f => f.userFunctionId === 40);
        this.modal.customUserFunctionString = customFunction != undefined
          ? customFunction.functionDescription
          : '';
        
      } catch {
        this.modal.functionsForUser = this.updatedUserFunctions.filter(f => 
          f.clubId == club.id &&
          f.userId == (this.$route.params?.id ?? 0
        ));
        
        this.modal.customUserFunctionString = '';
      }

      this.modal.element.show();
    },

    createFunctionsString(club) {
      if (club.functions === undefined || club.functions.length === 0) return "";

      const sortedFunctions = club.functions.sort((a, b) => a.userFunctionId > b.userFunctionId);

      let functionsString = sortedFunctions
        .filter(item => item.userFunction != '')
        .filter((item, index) => index < 3)
        .map(f => f.functionDescription).join(', ');
    
      if (sortedFunctions.length > 3) functionsString += '...';
      return functionsString;
    },

    handleClubDropDownEvent(clubId) {
      this.addClub(clubId);
      this.updateUserFunctionDictionary({ 
        clubId,
        userFunctionId: CUSTOM_USER_FUNCTION_ID, 
        functionState: true
      });
    },

    updateUserFunctionDictionary(data) {
      const element = this.updatedUserFunctions.find(e => 
        e.clubId === data.clubId && 
        e.userFunctionId === data.userFunctionId && 
        e.functionState !== data.functionState
      ); 

      if (element !== undefined)
      {
        this.updateAlreadyInsertedUserFunction(element, data);
        return;
      }
      
      if (!this.updateCustomUserFunction(data)) this.insertNewUserFunction(data);
    },

    updateAlreadyInsertedUserFunction(element, data) {
      const index = this.updatedUserFunctions.indexOf(element);
      this.updatedUserFunctions[index] = data;
      this.$emit('update:user-functions-state', this.updatedUserFunctions);
    },

    insertNewUserFunction(data) {
      this.updatedUserFunctions.push(data);
      this.$emit('update:user-functions-state', this.updatedUserFunctions);
    },

    updateCustomUserFunction(data) {
      const element = this.updatedUserFunctions.find(e => 
        e.clubId === data.clubId && 
        e.userFunctionId === CUSTOM_USER_FUNCTION_ID
      ); 

      if (
        element === undefined || 
        element.description === undefined ||
        element.description === data.description
      ) return false;

      const index = this.updatedUserFunctions.indexOf(element);
      this.updatedUserFunctions[index] = data;
      this.$emit('update:user-functions-state', this.updatedUserFunctions);

      return true;
    }
  },
}
</script>

<style>
.user-functions-list {
  display: block;
  font-weight: normal;
  font-size: 13px;
}
</style>